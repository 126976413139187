import Vue from 'vue'
import Vuex from 'vuex'
import { store } from './store/store'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import "babel-polyfill"
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/reset.css'
import _ from 'lodash'
import api from './assets/api/controllerApi.js';
import face from './assets/api/apiInterface.js';
import utile from './assets/public/utile.js';
// import flexible from './assets/public/flexible.js';
import Router from 'vue-router'
import 'animate.css'
import VueLazyload from 'vue-lazyload'

// 分享
import share from './assets/public/share.js'


Vue.prototype.$api = api
Vue.prototype.$face = face
Vue.prototype.$utile = utile
Vue.prototype.$share = share

Vue.use(Vuex)
Vue.use(MetaInfo)
Vue.use(Element)
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.5,
  error: 'https://www.zitisheji.com/error.png',
  loading: 'https://www.zitisheji.com/loading.gif',
  attempt: 2
})
Vue.config.productionTip = false


const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

router.beforeEach((to, from, next) => {
  if (to.name != "index") {
      // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
  }
  // 让页面回到顶部
  // if (to.name.indexOf('user') != -1) {
  //   to.query.userId = localStorage.getItem('userId')
  //   console.log(to)
  // }
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  // 这三句很重要，放进导航首位里面，就成功了，希望对你有帮助
  setTimeout(() => {
    next()
  },20)
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

