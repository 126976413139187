<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "App",
		data() {
			return {};
		},
		mounted() {
			this.sizeFun();
			var browerWidth = window.innerWidth; //浏览器可视宽度
			if (1180 < browerWidth < 1565) {
				var baseWidth = 1565; //设计稿宽度
				var zoomValue = browerWidth / baseWidth; //缩放比例计算
				console.log(browerWidth);
				
				if (zoomValue < 1) {
					document.getElementById("app").style.overflowX = 'hidden';
				}
			}
		},
		methods: {
			sizeFun() {
				// let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {

					//if (window.innerWidth < 760 && this.$route.name!='index') {

					let jumpUrl = "https://m.zitisheji.com/#/"
					//当前设备是移动设备
					// 视频

					if (window.location.href.indexOf('videoDetail') != -1) {
						let as = window.location.href.split('?')[1]; //获取?之后的参数字符串
						jumpUrl = 'https://m.zitisheji.com/#/pages/video/uni_videodetail?' + as
					}
					window.location.href = jumpUrl;
				}
			},
		},
		created() {

		},
		watch: {},
	};
</script>

<style>
	@font-face {
		src: url('https://oss.zitisheji.com/SOURCEHANSANSCN-REGULAR.OTF') format('truetype');
	}

	#app {}
</style>