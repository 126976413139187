import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			name: '/',
			component: () => import('../components/public.vue'),
			redirect: '/index',
			children: [{
					path: '/index',
					name: 'index',
					meta: {
						title: '首页',
					},
					component: () => import('../views/index.vue')
				}, {
					path: '/aboutLbk',
					name: 'aboutLbk',
					meta: {
						title: '关于刘兵克'
					},
					component: () => import('../views/aboutLbk.vue'),
				}, {
					path: '/aboutWebsites',
					name: 'aboutWebsites',
					meta: {
						title: '关于网站'
					},
					component: () => import('../views/aboutWebsites.vue'),
				}, {
					path: '/purchaseNotes',
					name: 'purchaseNotes',
					meta: {
						title: '购买须知'
					},
					component: () => import('../views/purchaseNotes.vue'),
				}, {
					path: '/certificate',
					name: 'certificate',
					meta: {
						title: '版权证书'
					},
					component: () => import('../views/certificate.vue'),
				}, {
					path: '/introduction',
					name: 'introduction',
					meta: {
						title: '字库介绍'
					},
					component: () => import('../views/introduction.vue'),
				},

				{
					path: '/refresh',
					name: 'refresh',
					meta: {
						title: ''
					},
					component: () => import('../views/refresh/refresh.vue')
				},
				{
					path: '/userAgreement',
					name: 'userAgreement',
					meta: {
						title: '协议中心'
					},
					component: () => import('../components/userAgreement.vue')
				}
			]
		},
		{
			path: '/agreement',
			name: 'agreement',
			meta: {
				title: ''
			},
			component: () => import('../components/agreement.vue')
		}

	]
})


export default router