const url = {

	//刘兵克字库
	bannerIndex: 'ziku_banner/index', //banner接口
	zikuMemberSite: 'ziku_member_site/index', //首页banner下方会员介绍
	zikuSingleInfo: 'ziku_single/info', //刘兵克字库单页接口（1:字库介绍，2：版权证书，3：购买需知，4：关于网站，5：关于刘兵克）
	zikupersonal: 'ziku_member_site/zikupersonal', //个人会员购买页基本信息
	zikuenterprise: 'ziku_member_site/zikuenterprise', //企业会员购买页基本信息
	getPayPrice: 'ziku_member_site/get_pay_price', //获取企业会员购买价格
	getQrcode: 'ziku_member_order/qrcode', //获取刘兵克字库支付二维码
	orderpaytype: 'ziku_member_order/orderpaytype', //查询刘兵克字库扫码订单支付状态
}

export default url